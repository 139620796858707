.button-elector__preview_fixed {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    max-width: 700px;
    margin: auto;
}

.button-elector__preview_fixed img {
    max-width: 100%
}

.button-elector__preview_screen {
    position: relative;
    width: 84%
}

.button-elector__preview_mobile {
    position: relative;
    width: 16%;
    margin-left: -10%
}

.button-elector__preview_mobile img {
    max-width: 100%;
    position: absolute;
    -webkit-transform: translateY(60%);
    transform: translateY(60%)
}

.button-elector__code {
    position: relative
}

.button-elector__copy-button {
    position: absolute;
    top: 16px;
    right: 16px
}

.button-elector__color {
    border: 2px solid #000 !important
}

.button-elector__color:focus {
    outline: none
}

.button-elector__icon-arrow {
    font-size: 20px;
    -webkit-animation-name: see-to-left;
    animation-name: see-to-left;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    transition: all .3s
}

@-webkit-keyframes see-to-left {
    0% {
        -webkit-transform: translate(0);
        transform: translate(0)
    }
    50% {
        -webkit-transform: translate(-5px);
        transform: translate(-5px)
    }
    to {
        -webkit-transform: translate(0);
        transform: translate(0)
    }
}

@keyframes see-to-left {
    0% {
        -webkit-transform: translate(0);
        transform: translate(0)
    }
    50% {
        -webkit-transform: translate(-5px);
        transform: translate(-5px)
    }
    to {
        -webkit-transform: translate(0);
        transform: translate(0)
    }
}

.button-elector__button-info {
    height: 62px;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 5px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #b5b5b5
}

.button-elector__button-info_right {
    grid-template-columns: 1fr auto
}


.PreviewFixedButton {
    position: absolute;
    border-radius: 0 0 3px 3px;
    height: 15px;
    border-width: 0;
    line-height: 1.3;
    padding: 0 7px 0 2px;
    font-size: 8px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    top: 50%;
    z-index: 1;
    transition: all .5s ease
}

.PreviewFixedButton.is-left {
    left: 13%;
    padding: 0 7px 0 0;
    -webkit-transform: rotate(-90deg) translate(-40%);
    transform: rotate(-90deg) translate(-40%);
    -webkit-transform-origin: calc(0% - 1px) 0;
    transform-origin: calc(0% - 1px) 0
}

.PreviewFixedButton.is-left svg {
    margin: 0 2px 1px !important
}

.PreviewFixedButton.is-right {
    right: 19.8%;
    -webkit-transform: rotate(90deg) translate(20%);
    transform: rotate(90deg) translate(20%);
    -webkit-transform-origin: 100%;
    transform-origin: 100%;
    left: 85%;
    padding: 0px 8px 0 7px;
    -webkit-transform: rotate(90deg) translate(-65%, -100%);
    transform: rotate(90deg) translate(-65%, -100%);
    -webkit-transform-origin: calc(0% - 1px) 0;
    transform-origin: calc(0% - 1px) 0
}

@media (max-width: 1200px) {
    .PreviewFixedButton.is-right {
        left: 78.3%
    }
}

@media (max-width: 991px) {
    .PreviewFixedButton.is-right {
        left: 76.8%
    }
}

@media (max-width: 767px) {
    .PreviewFixedButton.is-right {
        left: 74.9%
    }
}

@media (max-width: 575px) {
    .PreviewFixedButton.is-right {
        left: 78.9%
    }
}

@media (max-width: 360px) {
    .PreviewFixedButton.is-right {
        left: 77.9%
    }
}

.PreviewFixedButton.is-right svg {
    margin: 0 2px 0 4px !important
}

.PreviewFixedButton.is-mobile {
    padding: 0;
    margin: 0;
    left: 66%;
    border-radius: 50px;
    -webkit-transform: none;
    transform: none;
    width: 15px;
    height: 15px;
    top: 68%
}

.PreviewFixedButton.is-mobile svg {
    -webkit-transform: none;
    transform: none;
    height: 9px;
    margin: 0 0 0 3px !important;
    width: 9px
}

.PreviewFixedButton.is-mobile.is-right {
    left: 128%;
    -webkit-transform: translate(-50%);
    transform: translate(-50%)
}

.PreviewFixedButton .icon-quiz {
    height: 12px;
    width: 12px;
    padding: 2px;
    border-radius: 2px;
    margin: 0 4px 0 0
}

.icon-quiz {
    height: 40px;
    padding: 5px;
    border-radius: 5px;
    background: var(--color-text, #fff)
}

.icon-quiz.is-left {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    margin: -10px 10px -10px -25px
}


.icon-quiz.is-right {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    margin: -10px -25px -10px 10px
}
