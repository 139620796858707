.Leadforms__bg {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(25, 25, 25, 0.89);
    visibility: hidden;
    position: fixed;
    opacity: 0;
    z-index: -10000;
    overflow-x: hidden;
    overflow-y: auto;
    overflow-scrolling: touch;
    -webkit-overflow-scrolling: touch;
}

.Leadforms__modal {
    visibility: hidden;
    z-index: 100010;
    position: relative;
    margin: 12% auto 0;
    max-width: 1200px;
    width: 80%;
    transition: margin 0.2s ease-in;
}

#Leadforms__close {
    position: absolute;
    right: -40px;
    top: -40px;
    height: 45px;
    width: 45px;
    cursor: pointer;
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAABDklEQVRIS+1VSw6DIBAFNG3i1gu5dU24j/chcxov0/RDG4y0gMwMtnFXdxrmvTcz76EUBz/yYHzxJ2AnvBmR1voZqgBACSHe7xhaVpNgkgQeEADIPWmtnRCfXebnWQKKJFYedS2NMY219uG/FdVhhQHEAzjn7vnISt1i7cu19QQj7IQTEBdR8y2RhIUndUqpNowk74oNWkltDMKZgCXwYBgJB44uOWvTj8o7YiOmJidsBxUjIsO4K0RIkh0ANFjKUQLEiovan2w6TZOa53lJIeaWcRzPXdddvgoapTwGrBFSdFFOQFmx1Mmuy67G58MwtH3f3+LLjr0qjDEna+119T77P6DOsTlgf1nMgcMJXhBrhBl0VE+iAAAAAElFTkSuQmCC") no-repeat 100% 0;
    background-size: 70%;
    background-position: center;
    opacity: 0.5;
    border: none;
    z-index: 100020;
    box-sizing: border-box;
}

#Leadforms__close:hover {
    opacity: 0.9;
}

@media (max-width: 1439px) {
    .Leadforms__modal {
        margin-top: 6%;
    }
}

@media (max-width: 1023px) {
    .Leadforms__modal {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0;
        position: absolute;
        width: 100%;
        max-width: 100vw;
    }

    #Leadforms__close {
        right: 0;
        top: 0;
    }
}

.Leadforms__body-modal_open {
    overflow: hidden !important;
    position: fixed !important;
    width: 100%;
}

.Leadforms__button {
    display: inline-block;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: all 0.2s ease-out;
    color: var(--Leadforms-button-text-color, #fff) !important;
    border: 1px solid var(--Leadforms-button-color, #d34085);
    background-color: var(--Leadforms-button-color, #d34085);
    padding: 18px 21px;
    font-size: 22px;
    line-height: 1;
    cursor: pointer;
    text-decoration: none !important;
    overflow: hidden;
    position: relative;
}

.Leadforms__button:hover,
.Leadforms__button:focus {
    color: var(--Leadforms-button-text-color, #fff) !important;
    -webkit-filter: brightness(105%);
    filter: brightness(105%);
    text-decoration: none !important;
}

.Leadforms__button_shadow {
    box-shadow: 1.7px 5.8px 21px 0 var(--Leadforms-button-alpha-color, var(--Leadforms-button-color));
}

.Leadforms__button_blicked:after {
    content: '';
    position: absolute;
    top: -50%;
    bottom: -50%;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.6) 100%);
    width: 45px;
    -webkit-transform: skewX(-22deg);
    transform: skewX(-22deg);
    left: -65%;
    -webkit-animation-name: blick;
    animation-name: blick;
    -webkit-animation-duration: 3s;
    animation-duration: 3s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
}

@-webkit-keyframes blick {
    60% {
        left: 100%;
    }
    to {
        left: 130%;
    }
}

@keyframes blick {
    60% {
        left: 100%;
    }
    to {
        left: 130%;
    }
}

.Leadforms__button_rounded {
    border-radius: 50px;
}

.Leadforms__button_fixed {
    transition: none;
    padding: 1px 21px 3px 31px;
    line-height: 1.5;
    box-sizing: content-box;
    white-space: nowrap;
}

@media (max-width: 768px) {
    .Leadforms__button_fixed {
        display: block;
        -webkit-transform: none !important;
        transform: none !important;
        width: 55px;
        padding: 7px 7px 7px 8px !important;
        height: 55px;
        border-radius: 50px !important;
        bottom: 20px;
        top: auto !important;
    }

    .Leadforms__button_fixed-left {
        left: 15px !important;
    }

    .Leadforms__button_fixed-right {
        right: 15px !important;
        left: auto !important;
    }

    .Leadforms__button_fixed .Leadforms__text,
    .Leadforms__button_fixed .script__buttonText {
        display: none;
    }
}

.Leadforms__button_fixed.Leadforms__button_rounded {
    border-radius: 0 0 10px 10px;
}

.Leadforms__button_fixed.Leadforms__button_shadow {
    box-shadow: 1.7px 5.8px 21px 0 var(--Leadforms-button-alpha-color, var(--Leadforms-button-color));
}

.Leadforms__button_fixed-left {
    position: fixed;
    left: 0;
    -webkit-transform: rotate(-90deg) translate(-40%, 0);
    transform: rotate(-90deg) translate(-40%, 0);
    -webkit-transform-origin: calc(0% - 1px) 0%;
    transform-origin: calc(0% - 1px) 0%;
    top: 50%;
    z-index: 99999;
}

.Leadforms__button_fixed-left.Leadforms__button_rounded {
    border-radius: 0 0 10px 10px;
}

.Leadforms__button_fixed-right {
    position: fixed;
    left: 100%;
    -webkit-transform: rotate(-270deg) translate(-40%, 0);
    transform: rotate(-270deg) translate(-40%, 0);
    -webkit-transform-origin: calc(0% - 1px) 0%;
    transform-origin: calc(0% - 1px) 0%;
    padding: 1px 31px 3px 21px;
    top: 50%;
    z-index: 99999;
}

.Leadforms__button_fixed-right.Leadforms__button_rounded {
    border-radius: 0 0 10px 10px;
}

.Leadforms__button_fixed .icon-quiz {
    height: 19px;
    padding: 7px;
    border-radius: 5px;
    background: var(--color-text, #fff);
    box-sizing: content-box;
}

.Leadforms__button_fixed .icon-quiz path {
    fill: var(--color-text, var(--Leadforms-button-text-color, #d34085));
}
.Leadforms__button_fixed svg.icon-quiz {
    background-color: var(--Leadforms-button-color);
}


.Leadforms__button_fixed .icon-quiz.is-left {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    margin: 2px 10px -9px -27px;
}

.Leadforms__button_fixed .icon-quiz.is-right {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    margin: 2px -27px -9px 9px;
}

@media (max-width: 768px) {
    .Leadforms__button_fixed .icon-quiz {
        height: 22px;
        width: 20px;
        -webkit-transform: rotate(0) !important;
        transform: rotate(0) !important;
        margin: 8px 9px 5px 8px !important;
    }

    .Leadforms__button_fixed .icon-quiz.is-left {
        padding: 8px 7px 8px 10px !important;
    }
}

.Leadforms__button_red {
    background-color: #f86c6b;
    border-color: #f86c6b;
}

.Leadforms__button_yellow {
    background-color: #f8cb00;
    border-color: #f8cb00;
    color: #000;
}

.Leadforms__button_yellow:hover,
.Leadforms__button_yellow:focus {
    color: #000;
}

.Leadforms__button_blue {
    background-color: #20a8d8;
    border-color: #20a8d8;
}

.Leadforms__button_green {
    background-color: #4dbd74;
    border-color: #4dbd74;
}

.Leadforms__container {
    width: 100%;
    text-align: center;
}

.Leadforms__frame {
    visibility: hidden;
    height: 100%;
    width: 0;
    background: #fff;
    transition: height 0.2s ease-in;
}

@media (min-width: 1200px) and (max-width: 1439px) {
    .Leadforms__frame {
        height: 590px;
    }

    .Leadforms__modal {
        margin: calc((100vh - 590px) / 2) auto 0;
    }
}

@media (min-width: 1024px) and (max-width: 1199px) {
    .Leadforms__frame {
        height: 560px;
    }

    .Leadforms__modal {
        margin: calc((100vh - 560px) / 2) auto 0;
    }
}

@media (min-width: 1200px) and (max-width: 1439px) {
    .Leadforms__modal {
        width: 75%;
    }
}

@media (min-width: 1440px) {
    .Leadforms__frame {
        height: 650px;
    }

    .Leadforms__modal {
        margin: calc((100vh - 650px) / 2) auto 0;
    }
}

.Leadforms__bg_open {
    visibility: visible;
    z-index: 100000;
    opacity: 1;
}

.Leadforms__bg_open .Leadforms__modal {
    visibility: visible;
}

.Leadforms__frame_open {
    visibility: visible;
    width: 100%;
}
