.steps__step-title{
    font-size: 15px;
    font-weight: bold !important;
    margin: 14px 0 !important;
    text-align: center;


}
.steps{
    width: 100%;
    margin-top: 50px;
    position: relative;
}


.steps .caption{
    position: relative;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #fff;
    border: 3px solid #4cc724;
    text-align: center;
    box-sizing: border-box;
    color: #222;
    display: inline-block;
    margin: 7px 0 0;
    font-size: 1rem;
    line-height: 1.2;
    font-weight: 700;
}
.steps .steps__step {
    margin-bottom: 60px;
    text-align: center;
    position: relative;
    padding-bottom: 15px;
}
.steps .steps__step-img-wrap {
    width: 200px;
    height: 122px;
    overflow: hidden;
    margin-bottom: 15px;
    margin-right: auto;
    margin-left: auto;
}
.steps .steps__step-caption {
    color: #696969;
    font-size: 14px;
    z-index: 1;
}
.steps .steps__step-img {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
}

.steps
.steps__step:first-child
.steps__step-arrow,
.steps .steps__step:first-child:after, .steps .steps__step:last-child:before {
    display: none;
}

@media screen and (min-width: 576px){
    .steps .steps__step:before {
        width: calc(100% - 130px);
    }
}

@media screen and (min-width: 576px){
    .steps .steps__step:nth-child(odd):before {
        border-right: none;
    }
}

.steps-ul{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0;
    padding: 0;
    list-style-type: none;
    height: 295px;
}
.steps-ul li {
    position: relative;
    -ms-flex-preferred-size: 20%;
    flex-basis: 20%;
    height: 100%;
    text-align: center;
    box-sizing: border-box;
}
.steps-ul li :after{
    content: "";
    position: absolute;
    width: 156px;
    height: 116px;
}
.steps-ul li:first-child {
    padding-left: 90px;
}
.steps-img {

    align-self: flex-end;
    -ms-flex-item-align: end;
}
.two {
    margin-top: 0px;
    align-self: flex-start;
    -ms-flex-item-align:start;

}
.three {
    align-self: flex-start;
    -ms-flex-item-align:start;
    margin-top: 109px;
}
.four {
    margin-top: 0px;
    align-self: flex-start;
    -ms-flex-item-align:start;

}
.five{
    margin-top: 109px;
    -ms-flex-item-align:end;
    align-self:flex-end}
.steps-img img {
    margin-left: -70px;
    display: inline-block;
    margin-bottom: 10px;
}

.steps-img img{position:relative;left:-10px}
.steps-img img{display:inline-block;margin-bottom:10px}



.steps-ul{margin:0;padding:0;list-style-type:none;height:295px}
.steps-ul,.steps-ul li{display:-webkit-box;display:-ms-flexbox;display:flex}
.steps-ul li{position:relative;-ms-flex-preferred-size:20%;flex-basis:20%;height:100%;text-align:center;box-sizing:border-box}
.steps-ul li:after{content:"";position:absolute;width:156px;height:116px}
.steps-ul li:nth-child(odd):after{right: -37px;bottom:20px;background:url(/src/cp/static/img/line1.png) no-repeat 50%}
.steps-ul li:nth-child(2n):after{right: -8px;;bottom:20px;background:url(/src/cp/static/img/line2.png) no-repeat 50%}
.steps-ul li:last-child:after{display:none}
.steps-ul li:first-child{padding-left: 62px;}
.steps-ul li:nth-child(2){padding-left: 145px;}
.steps-ul li:nth-child(3){right: -175px;}
.steps-ul li:nth-child(3):after{padding-left: 4px;right: 25px;}
.steps-ul li:nth-child(4){right: 24px;}
.steps-ul li:nth-child(4):after{right: 25px;bottom: 20px;}
.steps-ul li:last-child{left: 265px}
.steps-ul li:last-child
.steps-ul img{position:relative;left:-10px}



.steps-time {
    position: relative;
    width: 55px;
    height: 55px;
    border-radius: 50%;
    background-color: #fff;
    border: 3px solid #4cc724;
    text-align: center;
    box-sizing: border-box;
}
.steps-time .two {
    background-color:#4cc724;
}
.steps-time5 {
    position: relative;
    width: 55px;
    height: 55px;
    border-radius: 50%;
    border: 3px solid #4cc724;
    text-align: center;
    box-sizing: border-box;
    background-color:#4cc724;

}
.steps-time5 span{
    color: #222;
    display: inline-block;
    margin: 7px 0 0;
    font-size: 1rem;
    line-height: 1;
    font-weight: 700;
}
.steps-time5 p{
    text-align: center;
    color: #222;
    margin: 0;
    font-size: .75rem;
    line-height: .5;
    font-weight: 400;
}
.steps-time span{
    color: #222;
    display: inline-block;
    margin: 7px 0 0;
    font-size: 1rem;
    line-height: 1;
    font-weight: 700;
}
.steps-time p{
    text-align: center;
    color: #222;
    margin: 0;
    font-size: .75rem;
    line-height: .5;
    font-weight: 400;
}
.steps-description{
    position: absolute;
    top: calc(100% + 12px);
    left: -79px;
    padding: 8px 10px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #e6e6e6;
    text-align: left;
    box-shadow: 1px 1px 3px 0 rgba(0,0,0,.25);
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all .2s linear;
    transition: all .2s linear;
    z-index: 1;
    opacity: 1;
    visibility: visible;
}


.steps-description:after,
.steps-description:before{
    content:"";
    position:absolute;
    border:10px solid transparent;
    left: 95px;
    top:-20px
}
.steps-description:before{
    border-bottom-color:#e6e6e6
}
.steps-description:after{
    top:-19px;
    border-bottom-color:#fff
}
.steps-description h2{
    font-size: .875rem;
    line-height: 1;
    white-space: nowrap;
    margin: 0;
    color: #222;
    font-weight: 700;
}
.steps-description p{
    margin: 5px 0 0;
    text-align: left;
    font-size: 12px;
    line-height: 1;
    white-space: nowrap;
}